@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100vh;
  background-color: rgb(27, 32, 35);
  margin: 0;
  padding: 0;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

a:active {
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

.swiper-pagination-bullet {
  background-color: #2c2f42 !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  width: 6px !important;
  height: 14px !important;
  border-radius: 3px !important;
  background-color: #146683 !important;
}

.scroller {
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller .scroller__inner {
  width: max-content;
  animation: scroll 20s linear infinite;
}

@layer base {
  * {
    @apply scrollbar-hide;
  }
}

@media screen and (min-width: 1000px) {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: flex !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex !important;
  }
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 1.25rem));
  }
}

@media screen and (min-width: 350px) {
  .swiper-container {
    width: 500px;
  }
}
@media screen and (min-width: 410px) {
  .swiper-container {
    width: 600px;
  }
}
@media screen and (min-width: 500px) {
  .swiper-container {
    width: 700px;
  }
}
@media screen and (min-width: 600px) {
  .swiper-container {
    width: 700px;
  }
}
@media screen and (min-width: 600px) {
  .swiper-container {
    width: 768px;
  }
}
@media screen and (min-width: 700px) {
  .swiper-container {
    width: 768px;
  }
}
@media screen and (min-width: 1000px) {
  .swiper-container {
    width: 1000px;
  }
}
@media screen and (min-width: 800px) {
  .swiper-container {
    width: 1300px;
  }
}

@media screen and (min-width: 1500px) {
  .swiper-container {
    width: 1600px;
  }
}

@media screen and (min-width: 2000px) {
  .swiper-container {
    width: 2000px;
  }
}
